
import Cloudfun, { defineComponent, ref, reactive, watch, computed, onMounted } from "@cloudfun/core";
import { version } from '../../../../package.json'
export default defineComponent({
  components: {
    
  },
  setup() {
    const versionCode = ref(version)
    console.log(versionCode);
    return {
      versionCode
    };
  }
})

